import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type contactState = {
    displayModal: boolean
};

const initialState = {
    displayModal: false,

} as contactState;

export const componentSlice = createSlice({
    name: "componentSlice",
    initialState,
    reducers: {
        setDisplayModal: (state, action: PayloadAction<boolean>) => {
            state.displayModal = action.payload
        },
    },

});

export const {
    setDisplayModal,
} = componentSlice.actions;
export default componentSlice.reducer;
