import React from 'react';
import number from '../../assets/images/home/section_10/number.png';
import Animated from 'components/generals/AnimatedComponent';
import ActionButton from 'components/ui/buttons/action-button';
import { setDisplayModal } from 'reduxStore/slices/component.slice';
import { useAppDispatch, useAppSelector } from 'reduxStore/hooks';
import { Link } from 'react-router-dom';

export default function Home__section__10() {
    const dispatch = useAppDispatch();

    return (
        <>
            <main className="nv__section nv__section--no-margin  nv__hp__section__10">
                <div className="overlay"></div>
                <section className="nv__section__container">
                    <div className="number">
                        <Animated animation={'animate__fadeInUp'}>
                            <img src={number} alt="Unidad de negocio" />
                        </Animated>

                        <Animated animation={'animate__fadeInRight'}>
                            <Link to="https://wa.me/5625083441" target="_blank">
                                <ActionButton
                                    text="Click para conocer las unidades de negocio"
                                    type="rounded--big"
                                    color="green"
                                    onClick={() => {
                                        dispatch(setDisplayModal(true));
                                    }}
                                />
                            </Link>
                        </Animated>
                    </div>
                </section>

                <div className="texture"></div>
            </main>
        </>
    );
}
