import React from 'react';
import logo from '../../assets/images/logos/ssd-logo-cream.png';
import texture from '../../assets/images/home/section_3/textura.png';
import ActionButton from 'components/ui/buttons/action-button';
import Animated from 'components/generals/AnimatedComponent';
import { Link } from 'react-router-dom';

export default function Home__section__3() {
    return (
        <>
            <main className="nv__section nv__section--no-margin  nv__hp__section__3">
                <section className="nv__section__container">
                    <div className="nv__hp__section__3__block logo__section">
                        <Animated animation={'animate__fadeInLeft'}>
                            <img src={logo} alt="Logo" />
                            <Link to="https://wa.me/5625083441" target="_blank">
                                <ActionButton
                                    text="¿Te interesa? Click aquí"
                                    type="rounded--big"
                                    color="green"
                                />
                            </Link>
                        </Animated>
                    </div>
                    {/* <div className="nv__hp__section__3__block"></div> */}
                </section>

                <div className="texture__section"></div>
            </main>
        </>
    );
}
