import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import church from '../../assets/images/home/church.png';
import ActionButton from 'components/ui/buttons/action-button';
import Animated from 'components/generals/AnimatedComponent';
import { Link } from 'react-router-dom';

export default function Home__section__1() {
    const location = useLocation();

    useEffect(() => {
        const hash = location.hash;
        if (hash) {
            setTimeout(() => {
                const element = document.querySelector(hash);
                if (element) {
                    element.scrollIntoView({ behavior: 'smooth' });
                }
            }, 0);
        }
    }, [location]);
    return (
        <>
            <main
                className="nv__section nv__section--no-margin nv__section--init  nv__hp__section__1"
                id="home"
            >
                <section className="nv__hp__section__1 nv__portrait">
                    <div className="nv__portrait__church">
                        <Animated animation={'animate__fadeIn animate__delay-2s'}>
                            <h1 className="nv__portrait__title">SANTO DOMINGO</h1>
                        </Animated>
                        <Animated animation={'animate__fadeIn'}>
                            <img src={church} alt="church" />
                        </Animated>
                    </div>

                    <section className="nv__container">
                        <div className="nv__portrait__info">
                            <Animated animation={'animate__fadeInDown  animate__delay-1s'}>
                                <h2>¡Explora una oportunidad única!</h2>
                            </Animated>
                            <Animated animation={'animate__fadeInDown  animate__delay-2s'}>
                                <p>
                                    Conviértete en socio accionista de los destinos más <br />
                                    exclusivos del mundo con The Spots Of The World.
                                </p>
                            </Animated>

                            <Animated animation={'animate__fadeInDown  animate__delay-3s'}>
                                <Link to="https://wa.me/5625083441" target="_blank">
                                    <ActionButton
                                        text={
                                            <>
                                                Inicia hoy con nuestro primer spot,
                                                <br /> Spot Santo Domingo.
                                            </>
                                        }
                                        type="rounded--big"
                                        color="cherry"
                                    />
                                </Link>
                            </Animated>
                        </div>
                    </section>
                </section>
            </main>

            <main className="nv__section nv__section--no-margin">
                <section className="nv__container">
                    <div className="section__1__info__mobile">
                        <h2>¡Explora una oportunidad única!</h2>
                        <p>
                            Conviértete en socio accionista de los destinos más <br />
                            exclusivos del mundo con The Spots Of The World.
                        </p>

                        <Link to="https://wa.me/5625083441" target="_blank">
                            <ActionButton text="INICIA HOY" type="rounded--big" color="cherry" />
                        </Link>
                    </div>
                </section>
            </main>
        </>
    );
}
