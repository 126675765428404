import React from 'react';
import figura_1 from '../../assets/images/home/section_4/figura_1.png';
import figura_2 from '../../assets/images/home/section_4/figura_2.png';
import ActionButton from 'components/ui/buttons/action-button';
import Animated from 'components/generals/AnimatedComponent';
import { Link } from 'react-router-dom';

export default function Home__section__4() {
    return (
        <>
            <main className="nv__section nv__section--no-margin  nv__hp__section__4">
                <section className="nv__section__container">
                    <section>
                        <Animated animation={'animate__fadeInDown animate__delay-1s'}>
                            <h1>
                                ¡Deja que Oaxaca
                                <br /> se adueñe de ti!
                            </h1>
                        </Animated>
                        <Animated animation={'animate__fadeInDown animate__delay-2s'}>
                            <Link to="https://wa.me/5625083441" target="_blank">
                                <ActionButton
                                    text="¿Te interesa? Click aquí"
                                    type="rounded--big"
                                    color="green"
                                />
                            </Link>
                        </Animated>

                        {/* <div className="figura figura--1">
                            <Animated animation={'animate__fadeInLeft animate__delay-3s'}>
                                <img src={figura_1} alt="figura" />
                            </Animated>
                        </div>

                        <div className="figura figura--2">
                            <Animated animation={'animate__fadeInRight animate__delay-4s'}>
                                <img src={figura_2} alt="figura" />
                            </Animated>
                        </div> */}
                    </section>
                </section>

                <div className="cirle cirle--1"></div>
                <div className="cirle cirle--2"></div>
                <div className="cirle cirle--3"></div>
                <div className="cirle cirle--4"></div>
                <div className="texture"></div>
            </main>
        </>
    );
}
