import React from 'react';
import { Modal } from 'antd';
import { setDisplayModal } from 'reduxStore/slices/component.slice';
import { useAppDispatch, useAppSelector } from 'reduxStore/hooks';
import { Autoplay, Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import slide_1 from 'assets/images/slides/eventos.png';
import slide_2 from 'assets/images/slides/galeria.png';
import slide_3 from 'assets/images/slides/igmezcaleria.png';
import slide_4 from 'assets/images/slides/restaurante.png';
import slide_5 from 'assets/images/slides/taste.png';
import slide_6 from 'assets/images/slides/terraza.png';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css';

export default function BussinessSliderModal() {
    const dispatch = useAppDispatch();
    const displayModal = useAppSelector((state) => state.componentSlice.displayModal);

    return (
        <Modal
            width={992}
            title=""
            open={displayModal}
            centered
            footer={null}
            onCancel={() => {
                dispatch(setDisplayModal(false));
            }}
        >
            <main className="nv__modal">
                <section className="nv__modal__content">
                    <Swiper
                        autoplay={{
                            delay: 3500,
                            disableOnInteraction: false,
                        }}
                        loop={true}
                        navigation={true}
                        pagination={{ clickable: true }}
                        modules={[Navigation, Pagination]}
                    >
                        <SwiperSlide>
                            <div className="nv__slider__bussines">
                                <img src={slide_1} alt="Eventos" />
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="nv__slider__bussines">
                                <img src={slide_2} alt="Galeria" />
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="nv__slider__bussines">
                                <img src={slide_3} alt="Igmezcaleria" />
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="nv__slider__bussines">
                                <img src={slide_4} alt="Restaurante" />
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="nv__slider__bussines">
                                <img src={slide_5} alt="Taste" />
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="nv__slider__bussines">
                                <img src={slide_6} alt="Terraza" />
                            </div>
                        </SwiperSlide>
                    </Swiper>
                </section>
            </main>
        </Modal>
    );
}
